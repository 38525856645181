exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-management-js": () => import("./../../../src/pages/access-management.js" /* webpackChunkName: "component---src-pages-access-management-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-identity-federation-js": () => import("./../../../src/pages/identity-federation.js" /* webpackChunkName: "component---src-pages-identity-federation-js" */),
  "component---src-pages-identity-governance-js": () => import("./../../../src/pages/identity-governance.js" /* webpackChunkName: "component---src-pages-identity-governance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-demo-js": () => import("./../../../src/pages/landing-demo.js" /* webpackChunkName: "component---src-pages-landing-demo-js" */),
  "component---src-pages-multi-domain-js": () => import("./../../../src/pages/multi-domain.js" /* webpackChunkName: "component---src-pages-multi-domain-js" */),
  "component---src-pages-multi-factor-authentication-js": () => import("./../../../src/pages/multi-factor-authentication.js" /* webpackChunkName: "component---src-pages-multi-factor-authentication-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-self-sovereign-identity-js": () => import("./../../../src/pages/self-sovereign-identity.js" /* webpackChunkName: "component---src-pages-self-sovereign-identity-js" */),
  "component---src-pages-visual-identity-orchestrator-js": () => import("./../../../src/pages/visual-identity-orchestrator.js" /* webpackChunkName: "component---src-pages-visual-identity-orchestrator-js" */)
}

